import React from "react"

export const Heart = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 588.6">
    <path
      className="beige"
      d="M406.1 133.4c-39.4-23.8-74.4-16.6-87.3 3.8-2.3 3.7-8.2.5-10.7-5.9-13.2-33.9-47.3-85.6-86.1-109-25.5-15.4-48.7-17.7-66.2-9.2l-.1-.1-2.1 1.2c-.9.5-9.1 5.2-10 5.7L35.2 82.3s85.4 287 87.8 299.6c2.3 12.6 105 184.6 105 184.6l111.9-64.3c1.3-.6 2.6-1.3 3.8-2.2L469 405.1c23-17.6 35.7-50.6 35.7-92.6 0-66.1-44.2-146.3-98.6-179.1z"
    />
    <path
      className="black"
      d="M140.2 13.2c.6-.4 9.2-5.3 10.1-5.8l7.5-4.3c19.6-6.9 43.7-2 67.9 12.6 38.9 23.5 74.4 74.5 89.2 112.7.1.3.3.6.4.9 16.1-20.1 53-27.5 94.4-2.5 56.4 34 102.2 117.3 102.2 185.8 0 44.6-13.8 79.7-38.7 98.8L348 506.2c-1.5 1.1-3.2 2.1-5.2 3l-117.3 67.5-3.7-6.1C197.5 530 118.2 396.5 115.7 383.4c-1.7-9-53.3-183.6-87.6-298.8l-1.8-6 113.9-65.4zm-10 367.2c2.9 10.3 61.6 110.8 100.4 175.9l105.8-60.9c1.6-.8 2.4-1.2 3-1.6L464.7 399c21.1-16.1 32.7-46.8 32.7-86.4 0-63.5-42.6-140.9-94.9-172.5-36-21.8-66.8-15.5-77.5 1.4-.9 1.4-2.1 2.4-3.4 3.3l44.2 64.6-74.2 80-127 26.3s-48.8-90-48.8-108.2c0-18.2 20.4-94.7 20.4-94.7l106.2-65.1c-7.7-7.3-15.7-13.7-23.9-18.6-22.3-13.5-43.4-16.6-59.5-8.8l-1.7.8c-1 .5-8.7 4.9-9.7 5.6L44 86c14.4 48.3 83.9 282.4 86.2 294.4z"
    />
    <path
      className="beige"
      d="M405.5 135c-26.4-15.9-50.7-17.7-67.8-11.2l-.2-.2-1.6.9c-3.4 1.5-6.5 3.2-9.2 5.3L224.8 189 172 361.8l52.7 196.6c1.3 4.9 6.7 7.3 11 4.8l103.5-59.5c1.3-.6 2.6-1.3 3.8-2.2l125.3-94.8c23-17.6 35.7-50.6 35.7-92.6.1-66-44-146.2-98.5-179.1z"
    />
    <path
      className="black"
      d="M218.8 183.9l104.4-60.7c2.3-1.9 6-4 9.9-5.7l5.5-3.2.9.9c20.5-6 45.6-1.3 69.6 13.2 56.4 34 102.2 117.4 102.2 185.8 0 44.6-13.8 79.7-38.7 98.8l-125.3 94.9c-1.5 1.1-3.2 2.1-5.2 3L239.4 570c-1.8 1-3.7 1.5-5.7 1.7l-1.4.9-.2-.7c-1.9 0-3.8-.4-5.7-1.2-4.2-1.9-7.4-5.6-8.6-10.2l-53.3-198.8 54.3-177.8zm183.1-42.2c-22-13.3-44.5-17.2-61.6-10.6l-1.2.5c-3.4 1.5-5.8 2.9-7.8 4.5L231 194.3 179.8 362l39.6 147.7 57.5-49.6 79.6-183.9 110-64.6c-17-29.3-39.7-54.9-64.6-69.9z"
    />
    <path d="M353.3 471.6C376.3 454 389 421 389 379c0-66.1-44.1-146.3-98.6-179.1-39.4-23.8-74.4-16.6-87.3 3.8-2.3 3.7-8.2.5-10.7-5.9-13.2-33.9-47.3-85.6-86.1-109-54.7-33-99.1-6.3-99.1 59.8 0 42.1 12.8 90.4 35.9 135.9l125.2 246c15.9 31.2 43.8 48 59.6 36l125.4-94.9z" />
    <path
      className="beige"
      d="M298.7 504.1c19.7-15.1 30.6-43.3 30.6-79.3 0-56.6-37.8-125.3-84.4-153.4-33.7-20.3-63.7-14.2-74.7 3.3-2 3.1-7 .5-9.1-5.1-11.3-29.1-40.5-73.3-73.7-93.3-46.9-28.3-84.9-5.4-84.9 51.2 0 36 10.9 77.4 30.7 116.4l107.2 210.7c13.6 26.7 37.5 41.1 51.1 30.8l107.2-81.3z"
    />
    <path
      className="black"
      d="M216.1 578c-2.6 0-5.3-.3-8-1-17.1-4.1-34.7-20.6-46.1-43L36.8 288C13.1 241.5 0 192 0 148.6c0-34.8 11.6-61 32.7-73.7 21.1-12.7 48.6-10.1 77.4 7.3 38.9 23.5 74.4 74.5 89.2 112.7.1.3.3.6.4.9 16.1-20 53-27.5 94.4-2.5 56.4 34 102.2 117.4 102.2 185.8 0 44.6-13.8 79.7-38.7 98.8l-125.3 94.8c-4.6 3.5-10.2 5.3-16.2 5.3zM62.2 82.3c-8.2 0-15.6 2-22.2 5.9-16.3 9.9-25.3 31.3-25.3 60.4 0 40.9 12.4 87.9 35 132.3l125.2 246c9.3 18.3 23.6 32.1 36.5 35.2 5 1.2 9.2.6 12.4-1.8L349 465.4c21.1-16.1 32.7-46.8 32.7-86.4 0-63.5-42.6-140.9-94.9-172.5-36-21.8-66.8-15.5-77.5 1.4-2.2 3.5-6.1 5.3-10 5-5.6-.6-10.9-5.4-13.6-12.2-13.6-34.9-47.3-83.7-83-105.2-14.4-8.8-28.2-13.2-40.5-13.2z"
    />
  </svg>
)
