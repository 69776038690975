import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { ArrowDown } from "../arrow-down"
import { ImageBackground } from "../image-background"
import { Link } from "../link"
import { Logo } from "../logo"
import { MultilineText } from "../multiline-text"
import s from "./contact.module.scss"
import { ContactDataProps } from "./dataProps"
import { Heart } from "./img/heart"

const COMPANY_CONTACT_ID = "company-contact"

export interface ContactProps extends ContactDataProps {}

export const Contact = ({
  contacts: { address, email, phone },
  googleMapSearch,
  getInTouchSlogan,
}: ContactProps) => (
  <>
    <ImageBackground outerClassName={s.mainImage}>
      <StaticImage
        placeholder="none"
        layout="fluid"
        maxWidth={5120}
        src="img/contact-main.png"
        alt="Keep in Touch"
        transformOptions={{}}
        blurredOptions={{}}
      />
      <Link
        id={s.mainImageLink}
        refId={COMPANY_CONTACT_ID}
        className="page-background-color"
      >
        <ArrowDown />
      </Link>
    </ImageBackground>
    <div id={COMPANY_CONTACT_ID} />
    <div id={COMPANY_CONTACT_ID} className={s.companyContact}>
      <div className="border-color">
        <Logo />
        <div id={s.address}>
          <MultilineText text={address} />
        </div>
        <div id={s.contact}>
          {phone}
          <a href={`mailto:${email}`} className="page-color">
            {email}
          </a>
        </div>
      </div>
    </div>
    <div id={s.map}>
      <iframe
        title="Map"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/search?key=${
          process.env.GATSBY_GOOGLE_API_KEY
        }&q=${googleMapSearch.replace(/ /g, "+")}`}
        allowFullScreen
      ></iframe>
    </div>
    <div id={s.getInTouch}>
      <div>
        <div>
          <div id={s.getInTouchText}>
            <MultilineText text={getInTouchSlogan} />
          </div>
          <div id={s.heart}>
            <Heart />
          </div>
        </div>
      </div>
    </div>
  </>
)
