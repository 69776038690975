import React, { ReactNode } from "react"

import s from "./image-background.module.scss"

export interface ImageBackgroundProps {
  id?: string
  outerClassName?: string
  children: ReactNode
}
export const ImageBackground = ({
  id,
  outerClassName,
  children,
}: ImageBackgroundProps) => (
  <div id={id} className={`${s.outer} ${outerClassName ? outerClassName : ""}`}>
    <div className={s.inner}>{children}</div>
  </div>
)
