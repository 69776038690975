// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable simple-import-sort/imports */
import React from "react"

import { Layout } from "../components/layout"
import { Contact } from "../components/contact"
import { contactContent } from "../content/contact"

const { pageTitle, ...contactProps } = contactContent

export default function ContactPage() {
  return (
    <Layout pageTitle={pageTitle} color="blue">
      <Contact {...contactProps} />
    </Layout>
  )
}
