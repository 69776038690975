// Do not edit this file! It is generated by prebuild script

import { ContactContent } from "../content-types"

export const contactContent: ContactContent = {
  pageTitle: "Contact",
  contacts: {
    address: ["Rue de Stassart 104", "Brussels 1050", "Belgium"],
    email: "info@wisdomtreasury.com",
    phone: "+32 474 84 76 49",
  },
  googleMapSearch: "Wisdom Treasury Consulting",
  getInTouchSlogan: ["Thank you"],
}
